import React from 'react';
import BodyClassName from 'react-body-classname';
import ClientItem from '../components/ClientItem';

const About = () => (

<BodyClassName className="about--page">
	<>
		<section className="section module aboutHero" style={{backgroundImage: "url(../images/Victoria__Whale__595147978.jpg)"}}>
			<div className="container">
				<div className="col-md-8 heading">
					<h2 className="text-light">Island Life.</h2>
					<h3 className="text-light">AmberSky Marketing Ltd. / est.2018</h3>
				</div>
			</div>
			<div className="svgBgBlock">
	        	<svg width="100%" height="100%" viewBox="300 0 150 1250">
	          		<path d="M 360 0 L 0 620 L 720 620 z" className="orange5" />
	          		<path d="M 720 620 L 360 0 L 1080 0 z" className="orange10" />
	          		<path d="M 1080 0 L 720 620 L 1440 620 z" className="orange90" />
	          		<path d="M 1440 620 L 1080 0 L 1800 0 z" className="orange30" />

	          		<path d="M 360 1250 L 0 620 L 720 620 z" className="orange10" />
	          		<path d="M 720 620 L 360 1250 L 1080 1250 z" className="amber100" />
	          		<path d="M 1080 1250 L 720 620 L 1440 620 z" className="orange100" />
	          		<path d="M 1440 620 L 1080 1250 L 1800 1250 z" className="amber90" />
	          		<path d="M 1800 1250 L 1440 620 L 2160 620 z" className="orange10" />
	        	</svg>
	      	</div>
		</section>
		<section className="section aboutWhy">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h4>Why us?</h4>
					</div>
					<div className="col-md-6">
						<h1>We enable success through valuable client-centered relationships.</h1>
					</div>
					<div className="col-md-5 ml-auto">
						<p>Learning about the people behind the product ensures we all benefit from communication and ultimately building on an evolving strategy to meet business goals. When put into action, you achieve success in your workflow and ultimately your finished product. We value the insight and depth these relationships provide and its this belief that gives us the opportunity to deliver unique solutions for our clients that present new avenues of growth.</p>
					</div>
				</div>
			</div>
		</section>
		<section className="section aboutProcess">
			<div className="container">
				<div className="row pb-5">
					<div className="col-md-4">
						<h4>Our Process</h4>
					</div>
					<div className="col-md-7 ml-auto">
						<p>Each client has their own set of business challenges deserving of a unique approach to solve them.  We simplify our process to easily break down the barriers that are often encountered. Within each step is an evolving workflow where we collaborate with each client to resolve their business issues.</p>
					</div>
				</div>
				<div className="row mt-5 pt-5 process">
					<div className="col-md-3">
						<h5>Meet/Discover</h5>
						<p>You always start with a conversation. We want to know about you and your business. To create a 'work-of-art', communication is key.</p>
						<ul>
							<li>Initial meet and greet.</li>
							<li>Introduction to stake holders.</li>
							<li>Learning and discussion.</li>
							<li>Setting expectations.</li>
						</ul>
					</div>
					<div className="col-md-3">
						<h5>Strategy</h5>
						<p>No project is complete without its overall strategy. We collaborate with you and your team to learn where your current presence falls short and reinforce on the strong.</p> 
						<ul>
							<li>Discover and create goals.</li>
							<li>Determine target markets.</li>
							<li>SWOT analysis.</li>
							<li>Define objectives.</li>
						</ul>
					</div>
					<div className="col-md-3">
						<h5>Production/QA</h5>
						<p>With a solid understanding and plan in place its time to build. We overlay each step of our strategy to produce an experience consumers will value. Testing and refining as the workflow evolves.</p>
						<ul>
							<li>Visual Concepts</li>
							<li>UX & UI Design.</li>
							<li>Coding development.</li>
							<li>User testing.</li>
							<li>Optimization strategy</li>
						</ul>
					</div>
					<div className="col-md-3">
						<h5>Post Launch</h5>
						<p>This is never the end and we know that every experience requires measurability and results. For some that means support and strategy to optimize for search and others maintaining and monitoring. We are focused on ensuring launch is seamless and are with you every step of the way.</p>
						<ul>
							<li>Analytics and reporting</li>
							<li>Customer feedback</li>
							<li>Optimization</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section className="section aboutLeadership">
			<div className="container text-light">
				<div className="row">
					<div className="col-md-5">
						<h4>The Team</h4>
					</div>
					<div className="col-md-5">
						<div className="row">
							<div className="col-md-6">
								<h5>Jeremy Estey</h5>
								<p>Business Development</p>
							</div>
							<div className="col-md-6">
								<h5>Kalani Diehl</h5>
								<p>Creative and Web</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="section aboutWork">
			<div className="container work-container text-light">
				<div className="row">
					<h4>Some of our work</h4>
				</div>
				<div className="row">

					<ClientItem clienturl="/work-northern-saskatchewan-wilderness-hunts" client="Northern Saskatchewan Wilderness Hunts" clientimg="../images/client-nswhunts.jpg" bgcolor="#fd9c00" color="orange" />
					<ClientItem client="MDRT Foundation of Canada" bgcolor="#6a205d" />
					<ClientItem client="Saskatchewan Goose Company" bgcolor="#fd9c00" />
					
				</div>
			</div>
		</section>
	</>
</BodyClassName>

)

export default About;