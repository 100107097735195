import React from 'react';
import Slider from '@farbenmeer/react-spring-slider';

const images = [
	'../images/client-nswhunts-site-b.png',
	'../images/client-nswhunts-site-a.png',
];

const imageStyle = (src: string) => ({
	backgroundSize: "contain",
	backgroundImage: `url(${src})`,
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center",
	height: "100%",
	width: "100%",
});

const WorkItemMdrt = () => (
<>
	<section className="section module clientHero" style={{backgroundColor: "#6a205d"}}>
		<div className="container">
			<div className="row">
				
						<div className="col-md-5 text-light mainText">
							<h2>MDRT Foundation of Canada</h2>
							<h1>A fresh new look to showcase the outdoors and all its wildlife.</h1>
							<p>Northern Saskatchewan Wilderness Hunts is a hunting outfitter that has established an outstanding reputation within their industry and to maintain that presence, they needed to update and refresh their website to properly showcase their service.</p>
							<p>We worked closely with NSW Hunts to extract needs and goals to construct a marketing strategy that encompassed the redesign of their website and an SEO plan.</p>
						</div>
						<div className="col-md-7 mainImage">
							<img alt="" src="../images/client-nsw-desktop.png" border="0" />
						</div>
					
			</div>
		</div>

	</section>
	<section className="section clientSlider">
		<div className="container">
			<Slider hasArrows auto={6000}>
				{images.map((image) => (
				<div key={image} draggable="true" style={imageStyle(image)}/>
				))}
			</Slider>
		</div>
	</section>
	<section className="section module clientDetails text-light">
		<div className="container" style={{paddingLeft: "3%"}}>
			<div className="row">
				<div className="col-md-7 left" style={{backgroundImage: "url(../images/client-nswhunts-site-c.png)"}}>
					<h3>Construct a marketing strategy to encompass needs and goals of redesign</h3>
				</div>
				
				<div className="col-md-4 offset-md-1 right">
					<h4>Strategic goal:</h4>
					<p className="pb-5">Gain more brand awareness and generate more quality leads through the engagement and simplified user experience.</p>
					<h4 className="mt-5">Website objectives:</h4>
					<ol>
						<li>Discover and establish understanding and clarity on goals.</li>
						<li>Spark life into an outdated design with updated visuals.</li>
						<li>Implement analytics and optimization strategies to increase and monitor traffic.</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
</>
)

export default WorkItemMdrt;
