import React from 'react';
import ContactForm from '../components/contactForm';
import BodyClassName from 'react-body-classname';

const Contact = () => (
	<BodyClassName className="contact--page">
	<>
		<section className="section module contactHero">
			<div className="container">
				<div className="col-md-5 heading">
					<h1>Let us help you get to where you want to be.</h1>
				</div>
			</div>
		</section>
		<section className="section contactForm">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<h4>Start the conversation</h4>
						<p>We’re here to support you through the challenges of your online presence. Our team is ready to assist and plan for your success. You’re not alone in this vast world of constant change and we take pride in treating you as a part of our family.</p>
					</div>
					<div className="col-md-7 ml-auto">
						<ContactForm />
					</div>
				</div>
				
			</div>
		</section>
		<div className="svgBgBlock">
        	<svg width="100%" height="100%" viewBox="1450 0 150 1250">
          		<path d="M 360 0 L 0 620 L 720 620 z" className="orange5" />
          		<path d="M 720 620 L 360 0 L 1080 0 z" className="orange10" />
          		<path d="M 1080 0 L 720 620 L 1440 620 z" className="orange90" />
          		<path d="M 1440 620 L 1080 0 L 1800 0 z" className="orange30" />

          		<path d="M 360 1250 L 0 620 L 720 620 z" className="orange11" />
          		<path d="M 720 620 L 360 1250 L 1080 1250 z" className="orange5" />
          		<path d="M 1080 1250 L 720 620 L 1440 620 z" className="orange100" />
          		
        	</svg>
      	</div>
	</>
	</BodyClassName>
);

export default Contact;

