import React, {useState} from 'react';
import {Link} from 'react-router-dom';

function Footer() {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);


	return (
		<>
			<section className="section footer">
				<div className="container">
					<div id={click ? 'logowhite' : 'logo'} className="nav-logo" onClick={handleClick}>
						<div className="logo">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.9 78.97">
								<polygon className="tri-1" points="38.64 31.27 12.92 31.27 25.84 0 38.64 31.27" fill="#051d30"/>
								<polygon className="tri-2" points="51.44 62.56 25.71 62.56 38.64 31.29 51.44 62.56" fill="#051d30"/>
								<polygon className="tri-3" points="25.73 62.54 0 62.54 12.93 31.27 25.73 62.54" fill="#051d30"/>
								<polygon className="tri-4" points="12.92 31.27 38.64 31.27 25.71 62.54 12.92 31.27" fill="#fd9c00"/>
								
								<path d="M55.41,65.39V24.87h8.15v3.92h.16a11.6,11.6,0,0,1,3.08-3.16,7.89,7.89,0,0,1,4.59-1.24,7.3,7.3,0,0,1,4.4,1.28,13,13,0,0,1,2.88,2.8A17.21,17.21,0,0,1,82,25.59a9.32,9.32,0,0,1,5.12-1.2,10,10,0,0,1,3.12.52,7.65,7.65,0,0,1,2.87,1.72,8.89,8.89,0,0,1,2.08,3.08A11.76,11.76,0,0,1,96,34.3V65.39H87.86v-28a5.45,5.45,0,0,0-1-3.52,3.6,3.6,0,0,0-3-1.28A3.45,3.45,0,0,0,80.66,34a8.13,8.13,0,0,0-.87,4.16V65.39H71.63v-28a5.39,5.39,0,0,0-1-3.52,3.58,3.58,0,0,0-3-1.28A3.44,3.44,0,0,0,64.44,34a8,8,0,0,0-.88,4.16V65.39Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M102.72,65.39V8.48h8.16V28.55H111a9.74,9.74,0,0,1,3.23-3.08,8.43,8.43,0,0,1,4.28-1.08,7.7,7.7,0,0,1,7.27,3.68,8.07,8.07,0,0,1,1,3.35,62.63,62.63,0,0,1,.24,6.48V53.32a35.9,35.9,0,0,1-.32,5.36,10.81,10.81,0,0,1-1,3.36q-2.07,3.83-7.11,3.83a7.64,7.64,0,0,1-4.64-1.2,19.87,19.87,0,0,1-3.11-3v3.68Zm16.23-28a6.55,6.55,0,0,0-.84-3.36A3.34,3.34,0,0,0,115,32.54,3.87,3.87,0,0,0,112,33.78a4.46,4.46,0,0,0-1.15,3.16V52.68A5.63,5.63,0,0,0,112,56.32a3.59,3.59,0,0,0,3,1.4,3.33,3.33,0,0,0,3-1.52,7.38,7.38,0,0,0,1-4Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M157.39,48.21H141.25v5.51a3.91,3.91,0,0,0,1.08,3,4,4,0,0,0,2.91,1c1.55,0,2.57-.45,3.08-1.36a7.32,7.32,0,0,0,.92-2.72h8.15a11.67,11.67,0,0,1-3.27,8.63,11.54,11.54,0,0,1-3.84,2.64,12.65,12.65,0,0,1-5,1,12.44,12.44,0,0,1-6.51-1.6A11,11,0,0,1,134.61,60a11.38,11.38,0,0,1-1.2-3.68,30.49,30.49,0,0,1-.32-4.56V38.46a30.59,30.59,0,0,1,.32-4.56,11.46,11.46,0,0,1,1.2-3.68A11,11,0,0,1,138.73,26a12.44,12.44,0,0,1,6.51-1.6,13,13,0,0,1,5.08,1A11.28,11.28,0,0,1,154.19,28a13.09,13.09,0,0,1,3.2,9Zm-16.14-6.4h8V37.18a4.94,4.94,0,0,0-1.08-3.48,4.24,4.24,0,0,0-5.83,0,4.94,4.94,0,0,0-1.08,3.48Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M163.39,65.39V24.87h8.15v4.31a24.83,24.83,0,0,1,4.87-3.51,12.49,12.49,0,0,1,5.92-1.28V33a6.63,6.63,0,0,0-2.48-.48,9.88,9.88,0,0,0-2.76.44,7.25,7.25,0,0,0-2.67,1.48,7.56,7.56,0,0,0-2.08,2.72,9.51,9.51,0,0,0-.8,4.15V65.39Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M212.46,24.87h-8.15V23A8.67,8.67,0,0,0,203,18.2a4.88,4.88,0,0,0-4.44-2,5.06,5.06,0,0,0-2.71.64,5.16,5.16,0,0,0-1.68,1.6,6.63,6.63,0,0,0-.88,2.35,15.41,15.41,0,0,0-.24,2.76,27.09,27.09,0,0,0,.12,2.8,5.37,5.37,0,0,0,.6,2,4.47,4.47,0,0,0,1.4,1.52,12.87,12.87,0,0,0,2.51,1.27l6.24,2.48A15.31,15.31,0,0,1,208.3,36a10.47,10.47,0,0,1,2.64,3.24,14.84,14.84,0,0,1,1.2,4.35,43.79,43.79,0,0,1,.32,5.56,29.27,29.27,0,0,1-.72,6.67,14,14,0,0,1-2.32,5.24A11.48,11.48,0,0,1,205,64.59a15.6,15.6,0,0,1-6.64,1.28,14.53,14.53,0,0,1-5.51-1,13,13,0,0,1-4.4-2.88,13.77,13.77,0,0,1-2.91-4.27,13,13,0,0,1-1.08-5.32v-3h8.15v2.55a6.65,6.65,0,0,0,1.32,4q1.32,1.8,4.43,1.8a7.14,7.14,0,0,0,3.24-.6,4.33,4.33,0,0,0,1.8-1.72,6.43,6.43,0,0,0,.76-2.68c.08-1,.12-2.2.12-3.47a36.39,36.39,0,0,0-.16-3.68,6.42,6.42,0,0,0-.64-2.32A4.74,4.74,0,0,0,202,41.81a19.88,19.88,0,0,0-2.44-1.2l-5.83-2.39q-5.28-2.16-7.08-5.72a19.77,19.77,0,0,1-1.79-8.91,20.53,20.53,0,0,1,.88-6.07,13.79,13.79,0,0,1,2.63-5,12.24,12.24,0,0,1,4.28-3.32A14.33,14.33,0,0,1,198.87,8a13.47,13.47,0,0,1,5.56,1.12,14.17,14.17,0,0,1,4.35,2.95,12.36,12.36,0,0,1,3.68,8.79Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M218.05,65.39V8.48h8.16V42.53h.16l9.83-17.66h8.15l-9.67,16.46,11.67,24.06h-8.87l-7.36-17.5L226.21,54V65.39Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
								<path d="M245.71,24.87h8.63l5.59,25.26h.16l5.52-25.26H274L262.33,72.43a13.77,13.77,0,0,1-1.8,4.47,11.08,11.08,0,0,1-2.68,2.88,9.56,9.56,0,0,1-3.23,1.56,14.47,14.47,0,0,1-3.56.44h-2.24V74.1h1.84a5.94,5.94,0,0,0,2.08-.32,3.31,3.31,0,0,0,1.6-1.44,14.49,14.49,0,0,0,1.2-3.15q.56-2,1.28-5.64Z" transform="translate(-1.1 -2.81)" fill="#051d30"/>
							</svg>
						</div>
						<div className="tagline">Success starts with a great relationship</div>
					</div>
					<div className="footerMenu">
						<Link to="/" className="nav-item">Home</Link>
						<Link to="/work" className="nav-item">Work</Link>
						<Link to="/about" className="nav-item">About</Link>
						<Link to="/contact" className="nav-item">Contact</Link>
					</div>
					
				</div>
				<div className="container">
					<div className="copyright">
						AmberSky Marketing Ltd. Copyright &copy; {(new Date().getFullYear())}
					</div>
				</div>
			</section>
		</>
	)
}

export default Footer;
