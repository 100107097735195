import React from 'react';
import BodyClassName from 'react-body-classname';
import ClientItem from '../components/ClientItem';


const Work = () => (
	<BodyClassName className="work--page">
	<>
		<section className="section module workWrap" style={{backgroundImage: "url(../images/triangle-ptrn.jg)",backgroundColor: "rgb(254,168,2)"}}>
			<div className="svgBgBlock">
		        <svg width="100%" height="100%" viewBox="700 0 150 1245">

		        	

		        	<path className="one" d="M 720 620 L 360 0 L 1080 0 z" fill="rgba(222,79,2,0.1)" />
		        	<path className="two" d="M 1080 0 L 720 620 L 1440 620 z" fill="rgba(247,102,19,0.3)" />
					<path className="four" d="M 1440 620 L 1080 0 L 1800 0 z" fill="rgba(238,75,2,0.3)" />
					<path className="three" d="M 1800 0 L 1440 620 L 2160 620 z" fill="rgba(220, 83, 38,0.4)" />
					<path className="four" d="M 2160 620 L 1800 0 L 2520 0 z" fill="rgba(222,79,2,0.6)" />
					<path className="three" d="M 2520 0 L 2160 620 L 2880 620 z" fill="rgba(222,79,2,0.7)" />
					
					
					<path className="three" d="M 1080 1250 L 720 620 L 1440 620 z" fill="rgba(222,79,2,0.06)" />
					<path className="four" d="M 1440 620 L 1080 1250 L 1800 1250 z" fill="rgba(247,102,19,0.45)" />
					<path className="five" d="M 1800 1250 L 1440 620 L 2160 620 z" fill="rgba(238,75,2,0.6)" />
					<path className="five" d="M 2160 620 L 1800 1250 L 2520 1250 z" fill="rgba(247,102,19,1)" />
		        </svg>
	      	</div>
			<div className="container">
				
					<div className="col-md-5 heading">
						<h1 className="text-light">Design to fit your business.</h1>
					</div>
				
			</div>
			<div className="overlay"></div>
		</section>
		<section className="section workItem">
			<div className="container work-container">
				<div className="row">
					{/* Need to have all the props to negate errors */}

					<ClientItem clienturl="/work-northern-saskatchewan-wilderness-hunts" client="Northern Saskatchewan Wilderness Hunts" clientimg="../images/client-nswhunts.jpg" bgcolor="#fd9c00" color="orange" />
					<ClientItem clienturl="/work-mdrt-foundation-canada" clientimg="../images/client-cmdrt.jpg" client="MDRT Foundation of Canada" bgcolor="#6a205d" color="yellow" />
					<ClientItem clienturl="/work-item" clientimg="../images/client-avalon-transformations.jpg" client="Saskatchewan Goose Company" bgcolor="#fd9c00" />
					<ClientItem clienturl="/work-item" clientimg="../images/client-avalon-transformations.jpg" client="Avalon Transformations" bgcolor="#166152" color="amber" />
				</div>
			</div>
		</section>
	</>
	</BodyClassName>
)

export default Work;
