import React from 'react';
import BodyClassName from 'react-body-classname';
import { Helmet } from 'react-helmet';

const Thankyou = () => (

<BodyClassName className="thankyou--page">
<>
	<section className="section module aboutHero">
	<Helmet>
		<title>Thank you for contacting AmberSky Marketing.</title>
		<meta name="description" content="Contact AmberSky Marketing and start the conversation. Let us help you get to where you want to be." />
	</Helmet>
	<div className="container">
		<div className="col-md-9 heading">
			<h2 className="text-dark">Thanks for contacting us.</h2>
			<h3 className="text-dark">We will be in touch shortly</h3>
		</div>
	</div>
	</section>
</>
</BodyClassName>

)

export default Thankyou;