import React, {Component} from 'react';
import emailjs from 'emailjs-com';

class ContactForm extends Component {
  
  	constructor() {
	    super();
	    this.state = {
	      name: '',
	      email: '',
	      company: '',
	      message: '',
	      status: 'Submit'
	    }
    }


    handleSubmit(event) {
	    
    	event.preventDefault();
    	this.setState({ status: "Sending" });

    	emailjs.sendForm('service_hsr42oo', 'template_4hcb92i', event.target, 'user_WCTJVKeE9tsGjwxO6io6B')
      	.then((result) => {
          	window.location.href = "https://amberskymarketing.com/thankyou";
      	}, (error) => {
          	console.log(error.text);
      	});
      	event.target.reset()

	}


    render() {
    	let buttonText = this.state.status;
    	return(
			
				
			<form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="name">Name</label>
							<input placeholder="Name" type="text" className="form-control" name="name" id="name" value={this.state.name} onChange={this.handleChange.bind(this)} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="exampleInputEmail1">Email address</label>
						  	<input placeholder="Email" type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.handleChange.bind(this)} />
						</div>
					</div>
				</div>

				<div className="form-group">
				  <label htmlFor="company" className="form-control">Company</label>
				  <input placeholder="Company" type="text" className="form-control" name="company" id="company" value={this.state.company} onChange={this.handleChange.bind(this)} />
				</div>

				<div className="form-group">
				  <label htmlFor="message">What can we do for you?</label>
				  <textarea placeholder="What can we do for you?" className="form-control" name="message" rows="4" id="message" value={this.state.message} onChange={this.handleChange.bind(this)} />
				</div>

				<button type="submit" className="btn btn-primary">{buttonText}</button>

			</form>
							
			
		);
    }


    handleChange(event) {
	    const field = event.target.id;
	    if (field === "name") {
	      this.setState({ name: event.target.value });
	    } else if (field === "email") {
	      this.setState({ email: event.target.value });
	    } else if (field === "company") {
	      this.setState({ company: event.target.value });
	    } else if (field === "message") {
	      this.setState({ message: event.target.value });
	     }
	}

}

export default ContactForm;