import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollTo';
import HomePage from './pages/HomePage';
import Work from './pages/Work';
import About from './pages/About';
import Contact from './pages/Contact';
import Thankyou from './pages/Thankyou';
import WorkItem from './pages/WorkItemNsw';
import WorkItemMdrt from './pages/WorkItemMdrt';



function App() {
  return (
  		<>
  		<Router>
      <ScrollToTop />
  			<Navbar />
  			<Switch>
  				<Route path="/" component={HomePage} exact />
  				<Route path="/work" component={Work} />
  				<Route path="/about" component={About} />
  				<Route path="/contact" component={Contact} />
          <Route path="/thankyou" component={Thankyou} />
  				<Route path="/work-northern-saskatchewan-wilderness-hunts" component={WorkItem} />
          <Route path="/work-mdrt-foundation-canada" component={WorkItemMdrt} />
  			</Switch>
        <Footer />
  		</Router>
    	</>
    )
}

export default App;
