import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import {Link} from 'react-router-dom';


const HomePage = () => (
	<ReactFullpage
		//fullpage options
		licenseKey = {'3AC65A19-31694594-8E16A2A6-53BEF6F1'}
		scrollingSpeed = {50} /* Options here */
		easingcss3 = 'cubic-bezier(0.055, 0.625, 0.400, 0.945)'
		verticalCentered = 'false'
		paddingTop = '30vh'


		render={({ state, fullpageApi }) => {
		return (
		  <ReactFullpage.Wrapper>
		    <div className="section orange">
		      	<div className="startHere" onClick={() => fullpageApi.moveSectionDown()}></div>
				<div className="container">
					<h1>Success <br />starts with a <br />great relationship.</h1>
				</div>
		      	<div className="svgBgBlock">
		        	<svg width="100%" height="100%" viewBox="900 0 150 1250">
		          		<path d="M 360 0 L 0 620 L 720 620 z" className="orange5" />
		          		<path d="M 720 620 L 360 0 L 1080 0 z" className="orange10" />
		          		<path d="M 1080 0 L 720 620 L 1440 620 z" className="orange90" />
		          		<path d="M 1440 620 L 1080 0 L 1800 0 z" className="orange30" />

		          		<path d="M 360 1250 L 0 620 L 720 620 z" className="orange10" />
		          		<path d="M 720 620 L 360 1250 L 1080 1250 z" className="amber100" />
		          		<path d="M 1080 1250 L 720 620 L 1440 620 z" className="orange100" />
		          		<path d="M 1440 620 L 1080 1250 L 1800 1250 z" className="amber90" />
		          		<path d="M 1800 1250 L 1440 620 L 2160 620 z" className="orange10" />
		        	</svg>
		      	</div>
		    </div>
		     
	    	<div className="section t-white" style={{backgroundImage: "url(../images/client-nswhunts.jpg)"}}>
	    		<div className="imgOverlay black"></div>
	    		<div className="container">
	    			<div className="col">
		    			<h2>Recent Work.</h2>
		    			<h4><Link to="/work-northern-saskatchewan-wilderness-hunts">Northern Saskatchewan Wilderness Hunts / 2020</Link></h4>
		    			<p>We wanted to help rebrand and bring more awareness to the outdoors of Northern Saskatchewan &mdash; showcasing some inspiring wildlife.</p>
		    		</div>
	    		</div>
	    		<div className="svgBgBlock">
			        <svg width="100%" height="100%" viewBox="1000 0 150 1150">
			        	<path d="M 720 620 L 360 0 L 1080 0 z" fill="rgba(222,79,2,0.2)" />
			        	<path d="M 1080 0 L 720 620 L 1440 620 z" fill="rgba(247,102,19,0.25)" />
						<path d="M 1440 620 L 1080 0 L 1800 0 z" fill="rgba(238,75,2,0.1)" />
						<path d="M 1800 0 L 1440 620 L 2160 620 z" className="orange10" />
						

						<path d="M 360 1250 L 0 620 L 720 620 z" className="orange10" />
						<path d="M 720 620 L 360 1250 L 1080 1250 z" fill="rgba(222,79,2,0.05)" />
						<path d="M 1080 1250 L 720 620 L 1440 620 z" fill="rgba(222,79,2,0.1)" />
						<path d="M 1440 620 L 1080 1250 L 1800 1250 z" fill="rgba(247,102,19,0.15)" />
						<path d="M 1800 1250 L 1440 620 L 2160 620 z" fill="rgba(238,75,2,0.1)" />
			        </svg>
		      	</div>
	    	</div>
		   
		    <div className="section t-white" style={{backgroundImage: "url(../images/Victoria__Whale__595147978.jpg)"}}> 
		    	<div className="imgOverlay amber-gradient"></div>
		    	<div className="container">
		    		<div className="col">
				    	<h2>Who are we?</h2>
				    	<h4>AmberSky Marketing Ltd. / est. 2018</h4>
				    	<p>Born in Victoria, helping local and business beyond borders, we believe success is built by forging strong relationships that results in precise communication of business practices and goals.</p>
				    </div>
				</div>
				<div className="svgBgBlock">
			        <svg width="100%" height="100%" viewBox="750 0 150 1150">
			        	
			        	<path d="M 1080 0 L 720 620 L 1440 620 z" fill="rgba(247,102,19,0.05)" />
						<path d="M 1440 620 L 1080 0 L 1800 0 z" fill="rgba(238,75,2,0.15)" />
						<path d="M 1800 0 L 1440 620 L 2160 620 z" fill="rgba(247,128,20,.50)" />
						
						<path d="M 360 1250 L 0 620 L 720 620 z" className="orange10" />
						<path d="M 720 620 L 360 1250 L 1080 1250 z" fill="rgba(222,79,2,0.05)" />
						<path d="M 1080 1250 L 720 620 L 1440 620 z" fill="rgba(222,79,2,0.1)" />
						<path d="M 1440 620 L 1080 1250 L 1800 1250 z" fill="rgba(247,102,19,0.30)" />
						<path d="M 1800 1250 L 1440 620 L 2160 620 z" fill="rgba(238,75,2,0.5)" />
			        </svg>
		      	</div>
		    </div>
		    <div className="section t-white" style={{backgroundImage: "url(../images/code-screen.jpg)"}}>
		    	<div className="imgOverlay gradient"></div>
		    	<div className="container">
		    		<div className="col">
		    			<h2>Qurnntly happening.</h2>
		    			<h4>A guide for a painless web development project <span className="date">/ Nov 10 2020</span></h4>
		    			<p>Succeeding from beginning to end can be a daunting task. Here are some crucial steps that can affect the overall quality of your development project.</p>
		    		</div>
		    	</div>
		    </div>
		    <div className="section footer" id="footer">
		    	<div className="container pb-5">
	    			
		    			<h2>Thanks for stopping by.</h2>
		    			<h4>Start the conversation</h4>
		    			<p className="mb-5 pb-5">Let's chat about your business and what we can do for you.</p>
		    			<a href="/contact" className="button">Contact us.</a>
		    		
	    		</div>
	    		<div className="container footerContainer">
				    <div className="footerNav">
				    	<p>All contents &copy; 2020 <Link className="pr-3 homeLink" to="/">AmberSky Marketing Ltd.</Link> <span>&mdash;</span> <Link className="text-dark" to="/work">Work</Link> <Link className="text-dark" to="/about">About</Link> <Link className="text-dark" to="/contact">Contact</Link></p>
				    </div>
				</div>
	    		<div className="svgBgBlock">
			        <svg width="100%" height="100%" viewBox="900 0 150 1050">
			        	
						<path d="M 1440 620 L 1080 0 L 1800 0 z" fill="rgba(238,75,2,1)" />
						<path d="M 1800 0 L 1440 620 L 2160 620 z" className="orange90" />
						
						<path d="M 1440 620 L 1080 1250 L 1800 1250 z" fill="rgba(247,102,19,1)" />
						<path d="M 1800 1250 L 1440 620 L 2160 620 z" fill="rgba(238,75,2,1)" />
			        </svg>
		      	</div>
		    	
		    </div>
		  </ReactFullpage.Wrapper>
		);
		}}
	/>
)

export default HomePage;
