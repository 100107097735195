import React from 'react';
import { Link } from 'react-router-dom';

function ClientItem (props) {
	return (
		<div className="col-md-4 work-item" style={{backgroundColor:props.bgcolor}}>
			<Link to={props.clienturl} className="work-item-box" style={{backgroundImage:"url("+props.clientimg+")"}}>
				<h3 className="text-light">{props.client}</h3>
				<div className={`work-item-slide ${props.color}`}></div>
				<div className="work-item-overlay"></div>
			</Link>
		</div>
	);
}

export default ClientItem;